import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export function AuthorTooltip({ title, aboutAuthor, name, suffix, trackingParent, trackingSubaction }) {
  const tooltipComponent = (
    <Fragment>
      {!!title && <div className="fw-bold mb-0_25">{title}</div>}
      {aboutAuthor ? (
        <ContentFragment>{aboutAuthor}</ContentFragment>
      ) : (
        `${name} is an automotive journalist at Edmunds.`
      )}
      {suffix}
    </Fragment>
  );
  const autohideProps = suffix ? { delay: { show: 0, hide: 250 }, autohide: false } : {};

  const onTooltipOpen = useCallback(() => {
    if (trackingParent) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_CONTENT,
          subaction_name: trackingSubaction || 'view_author_tooltip',
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: trackingParent,
          value: name,
        },
      });
    }
  }, [name, trackingParent, trackingSubaction]);

  return (
    <TooltipItem
      id={kebabCase(name)}
      onTooltipOpen={onTooltipOpen}
      className="top-0"
      iconClassName="medium text-gray-darker px-0_25"
      innerClassName="p-1"
      tooltipClassName="px-1"
      item={{
        placement: 'bottom',
        text: tooltipComponent,
      }}
      useUniqId
      {...autohideProps}
    />
  );
}

AuthorTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  aboutAuthor: PropTypes.string,
  tooltipSuffix: PropTypes.node,
};

AuthorTooltip.defaultProps = {
  title: null,
  aboutAuthor: null,
  tooltipSuffix: null,
};
