import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';

import {
  PricingCreativeConfigDefaultProps,
  PricingCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { PRICING_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';

import { getNonMSRPDefaultLabel, isNonMSRPMake, isOemUrlWhitelisted } from './data-helper';
import './pricing-site-served-ad.scss';
import './pricing-horizontal-site-served-ad.scss';

export function PricingHorizontalSiteServedAdPreprodUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  vehicle,
  mobile,
  position,
  cardClassName,
  wrapperClass,
  ivtClassName,
  showBorder,
  ads10779Recipe,
}) {
  const [naturalHeight, setNaturalHeight] = useState('40px');
  const {
    siteServedAdsUpdater,
    zipCode,
    creativeConfigData: {
      photoDisclaimer,
      photo,
      logo,
      headline,
      msrp: msrpConfig,
      disclaimerCopy,
      lineItemId,
      siteServedCreativeId,
      linkDisplayUrl,
      cta,
      isConquest,
      target,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  const msrp = msrpConfig?.trim();
  const make = get(vehicle, 'make.niceName', '');

  const isNonMsrpOem = isNonMSRPMake(make);
  const isWhiteListedUrl = isOemUrlWhitelisted(linkDisplayUrl);

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';

  const noMSRPLabelDisplay = msrp || getNonMSRPDefaultLabel(make);
  const noMSRPLabel = !!noMSRPLabelDisplay && <span className="mx-0_5">{noMSRPLabelDisplay}</span>;

  const nativeStyle = PRICING_AD.NATIVE_STYLE;
  const isChal2 = ads10779Recipe === 'chal2';
  const renderLogo = !isChal2 && (
    <img className="logo" style={{ position: 'absolute', maxHeight: '24px' }} alt="" src={logo} loading="lazy" />
  );
  const chal2LogoRef = useRef();

  useEffect(() => {
    if (isChal2) {
      const naturalHeightTemp = get(chal2LogoRef, 'current.naturalHeight') || parseInt(naturalHeight, 10);
      setNaturalHeight(`${naturalHeightTemp}px`);
    }
  }, [isChal2, naturalHeight]);

  return (
    <div
      className={classnames(
        'pricing-site-served-ad-unit pricing-horizontal preprod rounded w-100',
        wrapperClass,
        ivtClassName,
        {
          'is-whitelisted-oem-url': isWhiteListedUrl,
          'show-border': showBorder,
        }
      )}
      style={{ overflow: 'hidden' }}
    >
      <Card
        className={classnames(cardClassName, {
          'show-gradient-bg': ads10779Recipe === 'chal3',
        })}
        data-tracking-parent={nativeStyle}
        data-viewability={`${nativeStyle}-${position}`}
        innerRef={adRef}
      >
        <DfpTracking
          lineItemId={lineItemId}
          siteServedCreativeId={siteServedCreativeId}
          mobile={mobile}
          nativeStyle={nativeStyle}
          position={position}
          slotRenderEndListener={loadTrackingListener}
          siteServedAdsUpdater={siteServedAdsUpdater}
        />
        {adTrackers}
        <a
          href={ctaURL}
          target={ctaTarget}
          rel="noopener noreferrer"
          onClick={adClickTrackingListener}
          className="d-flex align-items-center no-focus h-100 text-decoration-none overflow-hidden"
        >
          <AdLabel />
          {isConquest ? (
            <ConquestRibbon style={{ position: 'absolute', left: 0, top: 0, height: '20px' }} />
          ) : (
            renderLogo
          )}
          <div className="ms-0_5 my-0_75 d-flex align-items-center" style={{ maxWidth: '135px', minHeight: '90px' }}>
            <img
              className={classnames('d-block mx-auto w-100', {
                'has-img-disclaimer': !!photoDisclaimer,
                background: !photo,
                'px-0_5 is-chal2': isChal2,
              })}
              style={
                isChal2
                  ? { maxHeight: naturalHeight, minWidth: '135px', objectFit: 'contain' }
                  : { minWidth: '135px', objectFit: 'contain' }
              }
              src={!isChal2 ? photo : logo}
              alt=""
              loading="lazy"
              width={135}
              ref={isChal2 ? chal2LogoRef : null}
            />
          </div>
          <div
            style={{
              flex: '1 1 auto',
            }}
            className="mx-1_5 my-0_75"
          >
            <div className="text-cool-gray-30 fw-bold">
              {headline && <ContentFragment componentToUse="span">{headline}</ContentFragment>}
              {(isNonMsrpOem && noMSRPLabel) ||
                (msrp && (
                  <span>
                    {` with a starting MSRP of `}
                    <ContentFragment componentToUse="span">{msrp}</ContentFragment>
                  </span>
                ))}
            </div>
            <div className="cta text-blue-40 text-transform-none size-16 py-0_25 fw-medium">
              <CtaButtonText text={`${cta} at ${linkDisplayUrl}`} underlineText />
            </div>
          </div>
          {photoDisclaimer && <div className="ps-0_75 mt-0_5 text-gray-darker size-10">{photoDisclaimer}</div>}
        </a>
        {disclaimerCopy && <Disclaimer disclaimerText={disclaimerCopy} buttonStyle={{ bottom: 0 }} />}
      </Card>
    </div>
  );
}

PricingHorizontalSiteServedAdPreprodUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: PricingCreativeConfigPropTypes,
  cardClassName: PropTypes.string,
  wrapperClass: PropTypes.string,
  showBorder: PropTypes.bool,
  ads10779Recipe: PropTypes.string,
};

PricingHorizontalSiteServedAdPreprodUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: PricingCreativeConfigDefaultProps,
  cardClassName: null,
  wrapperClass: null,
  showBorder: false,
  ads10779Recipe: null,
};

export const PricingHorizontalSiteServedAdPreprod = siteServedAdTrackingWrapper(PricingHorizontalSiteServedAdPreprodUI);
