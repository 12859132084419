import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';

import { PricingSiteServedAd } from 'client/site-modules/shared/components/native-ad/pricing-site-served-ad/pricing-site-served-ad';
import { PricingHorizontalSiteServedAd } from 'client/site-modules/shared/components/native-ad/pricing-site-served-ad/pricing-horizontal-site-served-ad';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { withSiteServedAdWrapper } from 'client/site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import { PRICING_AD } from 'client/site-modules/shared/components/native-ad/utils/constants';
import { PricingHorizontalSiteServedAdPreprod } from 'client/site-modules/shared/components/native-ad/pricing-site-served-ad/pricing-horizontal-site-served-ad-preprod';

import {
  AdComponentDefaultProps,
  AdComponentPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';

import {
  PricingCreativeConfigPropTypes,
  PricingCreativeConfigDefaultProps,
} from 'client/data/models/native-ads-creative-config';

export function PricingAdUI(props) {
  const {
    mobile,
    wrapperClass,
    cardClass,
    slotRenderEndListener,
    withDFPFallback,
    siteServedAdsEnabled,
    position,
    creativeConfig,
    currentVehicle,
    vehicle,
    placeholderComponent,
    placeholderComponentProps,
    ivtClassName,
    showHorizontalLayout,
    showOnlyDfpFallback,
    showOnlySiteServed,
    showBorder,
    showSiteServedWithDfpFallback,
    ads10779Recipe,
  } = props;

  const dfpFallback = (
    <NativeAd
      refreshDisableWhenZipChanged={siteServedAdsEnabled}
      wiredOnly={!mobile}
      mobileOnly={mobile}
      nativeStyle="filmstrip,pricing"
      position={position}
      wrapperClass={classnames(wrapperClass, ivtClassName, 'show-filmstrip')}
      slotRenderEndListener={slotRenderEndListener}
      customTargeting={{ showConquestRibbon: true }}
      customSizes={[[1, 1], [666, 76], [480, 76], [234, 350], [272, 284], 'fluid']}
      placeholderComponent={placeholderComponent}
      placeholderComponentProps={placeholderComponentProps}
      siteServedAdsUpdater={get(creativeConfig, 'siteServedAdsUpdater')}
    />
  );

  // V2 horizontal
  const isads10774Enabled = ['chal1', 'chal2', 'chal3'].includes(ads10779Recipe);
  const horizontalAd = isads10774Enabled ? (
    <PricingHorizontalSiteServedAdPreprod
      cardClassName={cardClass}
      wrapperClass={wrapperClass}
      ivtClassName={ivtClassName}
      mobile={mobile}
      position={position}
      creativeConfig={creativeConfig}
      vehicle={currentVehicle || vehicle}
      showBorder={showBorder}
      ads10779Recipe={ads10779Recipe}
    />
  ) : (
    <PricingHorizontalSiteServedAd
      cardClassName={cardClass}
      wrapperClass={wrapperClass}
      ivtClassName={ivtClassName}
      mobile={mobile}
      position={position}
      creativeConfig={creativeConfig}
      vehicle={currentVehicle || vehicle}
      showBorder={showBorder}
      isAds10380Enabled
      ads10380Recipe="chal1"
    />
  );

  if (showHorizontalLayout) {
    if (showSiteServedWithDfpFallback) {
      return withDFPFallback ? <div style={{ maxWidth: '260px' }}>{dfpFallback}</div> : horizontalAd;
    }
    if (withDFPFallback && showOnlyDfpFallback)
      return (
        <Fragment>
          <hr className="my-2" />
          {dfpFallback}
        </Fragment>
      );
    if (!withDFPFallback && showOnlySiteServed) {
      return horizontalAd;
    }
    return null;
  }

  // V1 vertical
  return withDFPFallback ? (
    dfpFallback
  ) : (
    <PricingSiteServedAd
      cardClassName={cardClass}
      wrapperClass={wrapperClass}
      ivtClassName={ivtClassName}
      mobile={mobile}
      position={position}
      creativeConfig={creativeConfig}
      vehicle={currentVehicle || vehicle}
    />
  );
}

PricingAdUI.propTypes = {
  ...AdComponentPropTypes,
  cardClass: PropTypes.string,
  creativeConfig: PricingCreativeConfigPropTypes,
  showHorizontalLayout: PropTypes.bool,
  showOnlyDfpFallback: PropTypes.bool,
  showOnlySiteServed: PropTypes.bool,
  showBorder: PropTypes.bool,
  showSiteServedWithDfpFallback: PropTypes.bool,
  ads10779Recipe: PropTypes.string,
};

PricingAdUI.defaultProps = {
  ...AdComponentDefaultProps,
  cardClass: undefined,
  creativeConfig: PricingCreativeConfigDefaultProps,
  showHorizontalLayout: false,
  showOnlyDfpFallback: false,
  showOnlySiteServed: false,
  showBorder: false,
  showSiteServedWithDfpFallback: false,
  ads10779Recipe: null,
};

const PricingAdWrapper = withSiteServedAdWrapper(PricingAdUI);

export function PricingAd(props) {
  return <PricingAdWrapper {...props} adType={PRICING_AD} />;
}
