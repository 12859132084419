import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dateFormat from 'dateformat';
import { isSameDay } from 'site-modules/shared/utils/date-utils';

export function ArticleDateline({ published, updated, publishedClassName, updatedClassName, textClassName, isInline }) {
  const showUpdatedDate = !!updated && new Date(updated) > new Date(published) && !isSameDay(updated, published);

  return (
    <Fragment>
      {!!published && (
        <span
          className={classnames('d-inline-block', textClassName, publishedClassName)}
          itemProp="datePublished"
          content={published}
        >
          {dateFormat(published, 'm/d/yyyy')}
        </span>
      )}
      {showUpdatedDate && (
        <span
          className={classnames('date-updated', textClassName, updatedClassName)}
          itemProp="dateModified"
          content={updated}
          style={isInline ? null : { display: 'block' }}
        >
          (updated {dateFormat(updated, 'm/d/yyyy')})
        </span>
      )}
    </Fragment>
  );
}

ArticleDateline.propTypes = {
  published: PropTypes.string,
  updated: PropTypes.string,
  textClassName: PropTypes.string,
  publishedClassName: PropTypes.string,
  updatedClassName: PropTypes.string,
  isInline: PropTypes.bool,
};

ArticleDateline.defaultProps = {
  published: null,
  updated: null,
  textClassName: 'small',
  publishedClassName: null,
  updatedClassName: null,
  isInline: null,
};
