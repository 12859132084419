import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get, compact } from 'lodash';
import { EditorialModel, EditorialEntities, buildMakeArticlesPath } from 'client/data/models/editorial';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { PageModel } from 'client/data/models/page';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { CAR_NEWS_LANDING_CONTENT_PATH } from 'site-modules/shared/constants/editorial/editorial';
import { getEntriesToRender, getNewsWidgetTitle, sortArticlesByDate } from 'site-modules/shared/utils/news-widget';
import { Link } from 'site-modules/shared/components/link/link';
import { PricingAd } from 'site-modules/shared/components/native-ad/pricing-ad/pricing-ad';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import { ColumnNewsWidgetCard } from './column-news-widget-card';

import './column-news-widget.scss';

const EXCLUDED_ARTICLES = {
  Volvo: {
    EX30: ['/car-news/2025-volvo-ex30-delayed.html'],
  },
};

export function ColumnNewsWidgetUI({
  newsContent,
  newsArticles,
  makeName,
  modelName,
  pathname,
  isMobile,
  className,
  creativeId,
  isAds10779Enabled,
}) {
  const title = getNewsWidgetTitle({ makeName, modelName, newsContent });
  const { articlesEntries, videoEntry } = getEntriesToRender({
    content: newsContent,
    newsArticles,
    makeName,
    modelName,
    pathname,
    fallbackToVehicleAgnosticContent: !(makeName && modelName),
    excludedArticles: EXCLUDED_ARTICLES?.[makeName]?.[modelName],
  });

  const entries = compact([...articlesEntries, videoEntry]).sort(sortArticlesByDate);

  return entries.length ? (
    <section
      className={classnames('column-news-widget d-flex flex-column ps-md-1', className)}
      style={isMobile ? {} : { borderLeftWidth: '2px', borderLeftStyle: 'solid' }}
      data-tracking-parent={creativeId}
    >
      <div className="mb-1">
        <Link to="/car-news/" className="heading-5 py-0_25">
          {title}
        </Link>
      </div>
      {entries.map((entry, ind) => (
        <ColumnNewsWidgetCard
          key={`${entry.id || entry.title}-${ind * 1}`}
          entry={entry}
          isNewsArticleEntry={entry.isNewsArticlesEntryType}
          className={classnames({ 'mb-1': videoEntry || ind !== articlesEntries.length - 1 })}
          isVideoCard={entry.isVideoEntry}
        />
      ))}
      {isAds10779Enabled && (
        <Experiment name="ads-10779" showDefault>
          <Recipe name="ctrl" isDefault />
          <Recipe name="chal1">
            <PricingAd
              wrapperClass="mt-1"
              mobile={isMobile}
              showHorizontalLayout
              showOnlySiteServed
              ads10779Recipe="chal1"
            />
          </Recipe>
          <Recipe name="chal2">
            <PricingAd
              wrapperClass="mt-1"
              mobile={isMobile}
              showHorizontalLayout
              showOnlySiteServed
              ads10779Recipe="chal2"
            />
          </Recipe>
          <Recipe name="chal3">
            <PricingAd
              wrapperClass="mt-1"
              mobile={isMobile}
              showHorizontalLayout
              showOnlySiteServed
              ads10779Recipe="chal3"
            />
          </Recipe>
        </Experiment>
      )}
    </section>
  ) : null;
}

ColumnNewsWidgetUI.propTypes = {
  newsContent: CmsEntities.Content,
  newsArticles: EditorialEntities.Articles,
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  creativeId: PropTypes.string,
  isAds10779Enabled: PropTypes.bool,
};

ColumnNewsWidgetUI.defaultProps = {
  newsContent: DEFAULT_CONTENT,
  newsArticles: [],
  makeName: '',
  modelName: '',
  pathname: '',
  isMobile: false,
  className: null,
  creativeId: null,
  isAds10779Enabled: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const ColumnNewsWidget = connect(mapStateToProps)(
  connectToModel(ColumnNewsWidgetUI, {
    newsContent: bindToContent(CAR_NEWS_LANDING_CONTENT_PATH),
    pathname: bindToPath('location.pathname', PageModel),
  })
);

export const MakeColumnNewsWidget = connect(mapStateToProps)(
  connectToModel(ColumnNewsWidgetUI, {
    newsContent: bindToContent(CAR_NEWS_LANDING_CONTENT_PATH),
    pathname: bindToPath('location.pathname', PageModel),
    newsArticles: bindToPath(({ makeSlug }) => buildMakeArticlesPath(makeSlug), EditorialModel, articlesData =>
      get(articlesData, 'results', [])
    ),
  })
);
